import React, { useState, useEffect, Suspense, lazy, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-bootstrap';

const Image = lazy(() => import('react-bootstrap/Image'));

function LazyImageWrapper(props) {
    const {rsId, ...restProps} = props
    const [isLoaded, setIsLoaded] = useState(false);
    const refImg = useRef(null);

    useEffect (()=> {
        if (refImg.current && isLoaded) {
            const contextMenuFn = (e) => {
                e.preventDefault();
            };

            const currentImg = refImg.current;
            currentImg.addEventListener('contextmenu', contextMenuFn);

            return () => {
                currentImg.removeEventListener('contextmenu', contextMenuFn);
            }
        }
    },[isLoaded, refImg]);
  
    return (
        <Image {...restProps} ref={refImg} onLoad= {()=>setIsLoaded(true)}className='image crop-image' onDragStart={e => e.preventDefault()}/>
    );
  }

function ListCarouselImage (props) {
    const [index, setIndex] = useState(0);
    const isDetail = useSelector((state) => {
        return state.properties.isDetail;
    });

    const images = props.property.Photos;
    const onViewDetail = props.onViewDetail || null;

    useEffect(() => {
        // Reset image if click to next property
        setIndex(0);
    }, [images]);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Suspense fallback={<div className="image">...</div>}>
            <div className="photo-container">
                <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
                    {
                        images.map((image, keyImage) => (
                            <Carousel.Item key={keyImage} onClick={onViewDetail && onViewDetail}>                
                                <LazyImageWrapper rsId={props.property.RsId} src={image} fluid />
                            </Carousel.Item>
                        ))
                    }
                </Carousel>
                <div className="property-sizes-area">
                    {isDetail === false && ((props.property.BuiltArea > 0 && props.property.BuiltAreaTo >= 0) || (props.property.BuiltArea === 0 && props.property.BuiltAreaTo > 0)) ?
                        (<span className="property-sizes">
                            <i className="icon icon-proe-built font-icon" />
                            <span>
                                {props.property.BuiltArea.toFixed(0)}
                                {props.property.BuiltArea < props.property.BuiltAreaTo && " - " + props.property.BuiltAreaTo.toFixed(0)}
                            </span>
                        </span>) : ''
                    }

                    {isDetail === false && ((props.property.TerraceArea > 0 && props.property.TerraceAreaTo >= 0) || (props.property.TerraceArea === 0 && props.property.TerraceAreaTo > 0)) ?
                        (<span className="property-sizes">
                            <i className="icon icon-proe-terrace font-icon" />
                            <span>
                                {props.property.TerraceArea.toFixed(0)}
                                {props.property.TerraceArea < props.property.TerraceAreaTo && " - " + props.property.TerraceAreaTo.toFixed(0)}
                            </span>
                        </span>) : ''
                    }

                    {isDetail === false && ((props.property.GardenPlotArea > 0 && props.property.GardenPlotAreaTo >= 0) || (props.property.GardenPlotArea === 0 && props.property.GardenPlotAreaTo > 0)) ?
                        (<span className="property-sizes">
                            <i className="icon icon-proe-garden font-icon" />
                            <span>
                                {props.property.GardenPlotArea.toFixed(0)}
                                {props.property.GardenPlotArea < props.property.GardenPlotAreaTo && " - " + props.property.GardenPlotAreaTo.toFixed(0)}
                            </span>
                        </span>) : ''
                    }
                    <span className="image-icon">
                        <i className="icon icon-proe-photo font-icon" />
                        <span> {index + 1}/{images.length}</span>
                    </span>
                </div>
            </div>
        </Suspense>
    );
}

export default ListCarouselImage;
